import React from "react";
import { graphql } from "gatsby";
import AboutPageTemplate from "./components/about-page";
import Layout from "../components/Layout";


const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        content={frontmatter.content}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query About2PageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        content
      }
    }
  }
`;
