import React from "react";
import { navigate } from "gatsby";
import showdown from "showdown";
import CircleButton from "../../components/CircleButton";


export const AboutPageTemplate = ({ content }) => {
  const converter = new showdown.Converter();
  const preparedContent = converter.makeHtml(content);

  return (
    <div
      className={`popup-text white`}
      style={{ background: "black" }}
    >
      <CircleButton
        top={3} left={3} type={"close"} color={"white"}
        fill={"black"}
        onClick={() => navigate("/")}
      />
      <div className='content' dangerouslySetInnerHTML={{ __html: preparedContent }}/>
    </div>
  );
};

export default AboutPageTemplate;
